import React, { useState, useEffect } from 'react'
import { Button } from 'primereact/button'
import { ProgressSpinner } from 'primereact/progressspinner'
import { DeleteComplaint } from './modalItens/modalDelete'
import { UploadFileComplaint } from './modalItens/modalUpload'
import { HeaderTitle } from '../../containers/headerTitle/HeaderTitle'
import { mapAsync } from '../../utils/generals'
import { Paginator } from 'primereact/paginator'
import {
  getComplaintFinish,
  cancelProtocol,
  fetchFile,
  attachReportRecord
} from '../../services/Api'
import Storage from '../../services/Storage'
import { DataView } from 'primereact/dataview'
import { SucessDialog } from '../complaintPage/SucessDialog'
import { ItemList, ButtonView, ComplaintEmpty } from './Styles'

const DataStorage = Storage('auth')

export const FinishComplaint = () => {
  const [allComplaints, setComplaints] = useState([])
  const [complaintsRender, setComplaintsRender] = useState([])
  const [first, setFirst] = useState(0)
  const [sucessModal, setSucessModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalUpload, setModalUpload] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)
  const [protocolSelect, setProtocolSelect] = useState({})

  useEffect(() => {
    // other code
    getItens()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getItens = async nextT => {
    setLoading(true)

    const user = (await DataStorage.get('user_payload')) || {}
    const allItems = await getAllItems(nextT, user.sub)

    setComplaints(allItems)
    setComplaintsRender(allItems.slice(0, 10))
    setLoading(false)

    return
  }

  const getAllItems = async (nextT, sub) => {
    const { reports } = await getComplaintFinish(nextT, sub)
    const { items, nextToken } = reports

    const nextPage = nextToken !== null ? await getAllItems(nextToken, sub) : []
    return items.concat(nextPage)
  }

  const UploadFile = async (fileUploaded, protocolId) => {
    try {
      setLoading(true)
      await mapAsync(fileUploaded, async file => {
        await fetchFile(file.getUploadUrl.uploadURL, file.item, file.item.type)
      })
      const sendBucket = fileUploaded.map(item => item.getUploadUrl)
      await attachReportRecord(protocolId, sendBucket)
      setSucessModal(true)
      getItens()
    } catch (e) {
      console.log(e)
    }
  }

  const onDeleteModal = complaint => {
    setModalDelete(true)
    setProtocolSelect(complaint)
  }
  const onUploadModal = complaint => {
    setModalUpload(true)
    setProtocolSelect(complaint)
  }

  const ComplaintDelete = async () => {
    setModalDelete(false)
    await cancelProtocol(protocolSelect.protocol)
    await getItens()
  }

  const onPagePress = e => {
    if (e.first < first) {
      const sliceItem = allComplaints.slice(first - 10, first)
      setComplaintsRender(sliceItem)
      setFirst(first - 10)
      return
    }
    const sliceItem = allComplaints.slice(e.first, e.first + 10)
    if (e.first >= allComplaints.length) return
    setComplaintsRender(sliceItem)
    setFirst(first + 10)
  }

  const renderListItem = complaints => {
    return (
      <div className='p-col-12'>
        <ItemList>
          <div>
            <div className='p-col-12'>
              Protocolo: <b>{complaints.protocol}</b>
            </div>
            <div className='p-col-12'>
              Status: <b>{'Aguardando Evidência'}</b>
            </div>
          </div>
          <ButtonView>
            <Button
              icon='pi pi-upload'
              style={{ height: '70%' }}
              onClick={() => onUploadModal(complaints)}
              tooltipOptions={{ position: 'top' }}
              tooltip='Adicionar Evidência'
            />
            <Button
              icon='pi pi-trash'
              style={{ height: '70%', marginInlineStart: '3px' }}
              className='p-button-danger'
              tooltip='Cancelar Protocolo'
              tooltipOptions={{ position: 'top' }}
              onClick={() => onDeleteModal(complaints)}
            />
          </ButtonView>
        </ItemList>
      </div>
    )
  }
  return (
    <div>
      <HeaderTitle cancel={false} title={'Concluir Denúncia'} />
      <SucessDialog
        sucessModal={sucessModal}
        protocol={''}
        newProtocol={() => setSucessModal(false)}
        modalText={'Sua Denúncia foi efetuada com sucesso'}
      />
      <DeleteComplaint
        complaint={protocolSelect}
        modalDelete={modalDelete}
        setModalDelete={setModalDelete}
        DeleteComplaint={ComplaintDelete}
      />
      <UploadFileComplaint
        modalUpload={modalUpload}
        setModalUpload={setModalUpload}
        complaint={protocolSelect}
        UploadFile={UploadFile}
      />
      {!loading && allComplaints.length !== 0 ? (
        <DataView
          value={complaintsRender}
          itemTemplate={complaintsRender => renderListItem(complaintsRender)}
          rows={complaintsRender.length}
          totalRecords={allComplaints.length}
          onPage={onPagePress}
          footer={
            <Paginator
              rows={complaintsRender.length}
              totalRecords={allComplaints.length}
              first={first}
              template={'PrevPageLink NextPageLink'}
              onPageChange={onPagePress}
            />
          }
        />
      ) : (
        <ComplaintEmpty>
          {!loading && allComplaints.length === 0 ? (
            <h2 style={{ fontWeight: 'bold' }}>Não a denúncias pendentes </h2>
          ) : (
            <ProgressSpinner style={{ width: '50px', height: '50px' }} />
          )}
        </ComplaintEmpty>
      )}
    </div>
  )
}
